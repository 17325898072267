
.welcome {
  color: white;
  height: calc(100vh - 50px);
  background: url("../../media/C-Hall-Landing-bg-1224.jpg");
  background-position: center;
  background-size: cover;
  padding: 50px 24px 0;
  .logos {
    
    justify-content: space-between;
  }
  .logo {
    &__c-hall {
      width: 200px;
      margin: auto;
    }
    &__welcome {
      width: 330px;
    }
    &__cosentino {
      width: 200px;
      position: absolute;
      margin: 0px;
      top: 40px;
      left: 50%;
      transform: translate(-50%, -50%);

    }
    &__happysharing {
      width: 200px;
      margin: 0px;
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }
  .container {
    padding: 40px 20px;
    text-align: center;
  }
  button {
    text-transform: uppercase;
    width: 170px;
    padding: 12px 0;
    font-family: "Neue Montreal Medium";
    color: #d7cdc1;
    background: none;
    border: 1px solid #9e644b;
    cursor: pointer;
    margin: 30px auto;
    
  }

  .disabled {
    cursor: not-allowed;
    color: #9ca4a5;
    background-color: rgb(100, 100, 100);
  }
  .countdown {
    font-family: "Diagramm Normal";
    text-align: center;
    width: 300px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    .numbers {
      top: 0px;
      position: relative;
      color: white;
      font-size: 48px;
    }
    .star {
      width: 262px;

    }
    .info_text {
      display: none;
      font-size: 15px;
    }
  }
}

@media (min-width: 768px) {
  .welcome {
    background: url("../../media/C-Hall-Landing-bg-1224.jpg");
    background-position: center;
    background-size: cover;
    .logo {
     
      &__cosentino {
        width: 200px;
        left: auto;
        top: 30px;
        right: 30px;
        transform: none;
      }
      &__happysharing {
        left: auto;
        top: 30px;
        left: 30px;
        transform: none;
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 160px 0px;
      text-align: left;
    }
    .countdown {
     //margin: initial;
      text-align: left;
      margin: 0px;
      width: 500px;
      position: relative;
      .numbers {
        position: absolute;
        font-size: 74px;
        top: 0px;
        left: 0px;
      }
      .star {
        position: fixed;
        right: 5%;
        top: 5%;
        width: 50%;
        max-width: 612px;
      }
      .info_text {
        display: none;
        font-size: 20px;
      }
    }
  }
}
