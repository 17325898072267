html,
body {
    /* height: 100%; */
    margin: 0;
    background-color: #010101;
}
@media (min-width: 1380px) and (min-height: 825px) {
    html,
    body {
        overflow: hidden;
    }
}
