@font-face {
  font-family: "Source Sans Pro";
  src: url("../media/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Neue Montreal Regular";
  src: url("../media/NeueMontreal-Regular.ttf");
}

@font-face {
  font-family: "Neue Montreal Medium";
  src: url("../media/NeueMontreal-Medium.ttf");
}

@font-face {
  font-family: "Diagramm Normal";
  src: url("../media/Diagramm-Normal.ttf");
}
@font-face {
  font-family: "Diagramm SemiBold";
  src: url("../media/Diagramm-SemiBold.ttf");
}

$background-black: #010101;
$primary-color: #323130;
$light-primary-color: #605e5c;
$background-streaming: #323130;
$languageFont: "Source Sans Pro";
