@import "../../styles/variables.scss";

.language {
  color: #d7cdc1;
  font-family: "Neue Montreal Regular";
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  background: url("../../media/C-Hall-Landing-bg-1224.jpg");
  background-position: center;
  background-size: cover;
  padding: 50px 24px 0;
  height: calc(100vh - 50px);
  //height: calc(100vh - 1px);

  .container {
    padding: 40px 0;
    text-align: center;
  }
  //padding: 30vh 0 25vh;
  .text-container {
    font-size: 14px;
    text-align: center;
    padding: 20px;
    div:nth-child(2) {
      margin-top: 6px;
    }
  }
  .button-container {
    
    button {
      width: 120px;
      margin: 8px;
      padding: 5px 0;
      font-family: "Neue Montreal Regular";
      color: #d7cdc1;
      background: none;
      border: 1px solid #9e644b;
      cursor: pointer;
    }
  }
  .logo {
    &__c-hall {
      width: 200px;
      margin: auto;
    }
    &__cosentino {
      width: 200px;
      position: absolute;
      margin: 0px;
      top: 40px;
      left: 50%;
      transform: translate(-50%, -50%);

    }
    &__happysharing {
      width: 200px;
      margin: 0px;
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, -50%);

    }

  }
}

@media (min-width: 768px) {
  .language {
    //padding: 30vh 0 25vh;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 0px;
      text-align: center;
    }
    .text-container {
      font-size: 15px;
      text-align: center;
      padding: 25px;
      margin: auto;
      div:nth-child(2) {
        margin-top: 5px;
      }
    }
    .logo {
      &__C-Hall {
        width: 200px;
      }
      &__Cosentino {
        width: 120px;
      }
     
      &__cosentino {
        width: 200px;
        left: auto;
        top: 30px;
        right: 30px;
        transform: none;
      }
      &__happysharing {
        left: auto;
        top: 30px;
        left: 30px;
        transform: none;
      }

     
    }
  }
}
