.streaming {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  justify-content: space-around;

  background-color: black;
  text-align: center;
  padding: 40px 0;
  .logo {
    &__c-hall {
      width: 90px;
      margin-bottom: 15px;
    }
    &__cosentino {
      width: 100px;
      margin-top: 20px;
    }
    &__happysharing {
      width: 200px;
      margin-top: 10px;
     

    }
  }
}

@media (min-width: 768px) {
  .streaming {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: initial;
    justify-content: space-around;

    padding: 115px;
    .logo {
      &__c-hall {
        width: 150px;
      }
      &__cosentino {
        width: 130px;
      }
    }
  }
}
